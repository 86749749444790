import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'

const Button = loadable(() => import('/src/components/Basic/Buttons/Button'))

const SubmitButton = ({
    icon,
    initialText,
    sentText,
    isSubmitting,
    isSubmitted,
    disabled,
    onClick,
    className
}) => {
    const classes = [
        ...(isSubmitting
            ? ['btn-primary btn-submitting']
            : isSubmitted
            ? ['btn-success btn-check']
            : ['btn-primary btn-arrow']),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <Button
            type={'submit'}
            size={'lg'}
            icon={icon}
            className={concatenatedClasses}
            disabled={isSubmitting || isSubmitted || disabled}
            onClick={onClick}
        >
            {isSubmitting
                ? 'Processing...'
                : isSubmitted
                ? sentText
                : initialText}
        </Button>
    )
}

SubmitButton.propTypes = {
    /**
     * Icon
     */
    icon: PropTypes.string,
    /**
     * Initial button text
     */
    initialText: PropTypes.string.isRequired,
    /**
     * Submitted button text
     */
    sentText: PropTypes.string.isRequired,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Optional disabled condition
     */
    disabled: PropTypes.bool,
    /**
     * onClick function
     */
    onClick: PropTypes.func
}

SubmitButton.defaultProps = {
    icon: 'arrow',
    initialText: 'Send enquiry',
    sentText: 'Sent!',
    isSubmitting: false,
    isSubmitted: false
}

export default SubmitButton
